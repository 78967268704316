body {

  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



@media print {

  body {
    overflow: visible !important;
    margin: 25mm 25mm 25mm 2mm;

  }


  .printing_content  * {
    padding: 0;
    display: block !important;

    font-family: unset !important;
    font-size: 14pt !important;
  }



  .table_printing_content  .ms-ScrollablePane  * {
    padding: 0;
    overflow: visible !important;
    font-family: unset !important;
    font-size: 14pt !important;
    display: table-row !important
  }

  .ms-OverflowSet {
    visibility: hidden;
  }

  .ms-Icon {
    visibility: hidden;
  }

  .ms-Persona-coin {
    visibility: hidden;

  }

}